<template>
  <label
    :class="{'!cursor-not-allowed' : disabled}"
    class="inline-flex items-center cursor-pointer"
  >
    <input
      v-model="checkedValue"
      type="checkbox"
      class="sr-only peer"
      :disabled="disabled"
    >
    <div
      :class="[{'after:!bg-neutral-150' : disabled}, switchClass]"
      class="relative w-11 h-6 bg-neutral-100 peer-focus:outline-none  rounded-full peer   peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:bg-primary  after:content-[''] after:absolute after:top-[2px] after:start-[2px]  peer-checked:after:border-primary after:border-neutral-500 after:bg-neutral-500 after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-primary-light"
    />
  </label>
</template>
<script setup lang="ts">

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  switchClass: {
    type: [String, Object, Array],
    default: '',
  },
})

const emits = defineEmits<{(e: 'update:modelValue', value: boolean): void }>()

const checkedValue = computed({
  get() {
    return props.modelValue
  },
  set(newValue: boolean) {
    return emits('update:modelValue', newValue)
  },
})
</script>
